import React from 'react';
import button from './button.png';
import Typing from 'react-typing-animation';

const Content = () => {
    return (
        <div className="parent-content">
            <div className="content" >
                <div className="desc">
                    <img
                        style={{
                            marginBottom :'10px'
                        }}
                        src={button} />
                    <Typing cursorClassName="text-white" speed={20}>
                    <p style={{
                        color : '#B2B1B9'
                    }} className="text-comment"># hy! maaf ya, saat ini webnya sedang dalam <span className="text-blue">perbaikan</span> :( . . .
                        <Typing.Cursor />
                    </p>
                    <span className="text-dark">.</span>
                    </Typing>
                </div>
            </div>
        </div>
    )
}

export default Content